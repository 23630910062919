import React from 'react';
import PageLayout from '@/components/pageLayout';
import AddAlertForm from './components/addAlertForm';
import { useTranslation } from 'react-i18next';
import { List, Divider, Popover, Col, Row, Switch, Steps, Select, Card, Form, Input, Button, message, InputNumber } from 'antd';
import './index.less';
import { useState, useEffect } from 'react';
import { listAlertTemplatePlatform, listAlertTemplate, previewAlertTemplatePromql } from '@/services/warning';
import { useHistory } from 'react-router-dom';

const CreateAlertByAlertTemplate: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { Step } = Steps;
  const [form] = Form.useForm();

  const [templateList, setTemplateList] = useState<any[]>([]);
  const [varsList, setVarsList] = useState<any[]>([]);
  const [promql, setPromql] = useState<string>('');
  const [isShow, setIsShow] = useState<boolean>(false);
  const [currentStep, setcurrentStep] = useState<number>(0);
  const [tid, setTid] = useState<number>(0);
  const [cluster, setCluster] = useState<string>('');
  const [platformVarsList, setPlatformVarsList] = useState<any[]>([]);
  const [platforms, setPlatforms] = useState<any[]>([]);
  const [platformTemplates, setPlatformTemplates] = useState<any[]>([]);

  const handleTemplateChange = (val) => {
    templateList?.map((item) => {
      if (item.id == val) {
        setVarsList(item.varsList);
        setTid(val);
      }
    });
  };

  const getAlertTemplateList = async () => {
    const { success, dat } = await listAlertTemplate(0);
    if (success) {
      setTemplateList(dat);
    }
  };

  const goNext = () => {
    form.validateFields().then(() => {
      setcurrentStep(currentStep + 1);
    });
  };

  const goBack = () => {
    setcurrentStep(currentStep - 1);
  };

  const preview = () => {
    form
      .validateFields()
      .then(async (values) => {
        values = fillTimeUnit(values);
        values.platform_var = values.platform_vars;
        delete values.platform_vars;
        if (!values.subscribe_all && !values.tid) {
          message.error('请选择告警模板');
          return;
        }

        const res = await previewAlertTemplatePromql(values);
        if (res.err) {
          message.error(res.error);
        } else {
          setIsShow(true);
          setPromql(res.dat.promql);
          setCluster(res.dat.cluster);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAlertTemplateList();
  }, []);

  // 补齐time类型的单位
  const fillTimeUnit = (data) => {
    let res = data;
    let platformTimeVars = platformVarsList?.find((item) => item.type === 'time');
    let templateTimeVars = varsList?.find((item) => item.type === 'time');
    if (platformTimeVars) {
      res.platform_vars[platformTimeVars.en] = res.platform_vars[platformTimeVars.en] + platformTimeVars.unit;
    }
    if (templateTimeVars) {
      res.template_var[templateTimeVars.en] = res.template_var[templateTimeVars.en] + templateTimeVars.unit;
    }
    return res;
  };

  const handleChangeSubscribeAll = () => {
    setVarsList([]);
    setIsShow(false);
    form.setFieldsValue({ tid: undefined });
  };

  const getAlertTemplatePlatform = async () => {
    const { success, dat } = await listAlertTemplatePlatform(0);
    if (success) {
      setPlatforms(dat || []);
    }
  };
  useEffect(() => {
    getAlertTemplatePlatform();
  }, []);

  const handlePlatformChange = (pid) => {
    setPlatformTemplates([]);
    setVarsList([]);
    setPlatformVarsList([]);
    form.setFieldsValue({ tid: undefined });
    setPlatformVarsList(platforms.find((p) => p.id === pid)?.varsList);
    setPlatformTemplates(templateList.filter((item) => item.platform_id == pid));
  };

  return (
    <PageLayout title={t('告警规则')} showBack hideCluster>
      <div className='operate_con'>
        <Steps current={currentStep}>
          <Step title='第一步' description='选择平台、模板' />
          <Step title='第二步' description='补充告警规则信息' />
        </Steps>
        {currentStep == 0 && (
          <Card
            style={{
              marginTop: 5,
            }}
          >
            <Form form={form} name='form'>
              <Form.Item label='平台' name='platform_id' rules={[{ required: true, message: '平台不能为空！' }]}>
                <Select onChange={handlePlatformChange} options={platforms.map((item) => ({ label: item.name, value: item.id }))} placeholder='请选择平台' />
              </Form.Item>
              {platformVarsList?.length > 0 && <Divider plain>平台变量</Divider>}
              {platformVarsList?.map((item, i) => (
                <Row gutter={10} key={i}>
                  <Col span={8}>
                    <Form.Item
                      label={`${item.cn}`}
                      name={['platform_vars', `${item.en}`]}
                      key={i}
                      rules={[
                        {
                          required: true,
                          message: `${item.en}不能为空`,
                        },
                      ]}
                      initialValue={item.default}
                      extra={item.type === 'time' && <>单位：{item.unit}</>}
                    >
                      {item.type === 'time' || item.type === 'number' ? (
                        <InputNumber style={{ width: 250, margin: 3 }} placeholder='请输入该变量对应的值' step={0.1} min={item.min} max={item.max}></InputNumber>
                      ) : (
                        <Input placeholder='请输入该变量对应的值' />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Popover
                      content={
                        <>
                          <p>类型：{item.type}</p>
                          {item.type === 'time' && <p>单位：{item.unit}</p>}
                          <p>默认值：{item.default}</p>
                          {(item.type === 'time' || item.type === 'number') && <p>最小值：{item.min}</p>}
                          {(item.type === 'time' || item.type === 'number') && <p>最大值：{item.max}</p>}
                          <p>备注说明：{item.comment}</p>
                        </>
                      }
                      title={item.cn}
                    >
                      <Button type='primary'>查看该变量详情</Button>
                    </Popover>
                  </Col>
                </Row>
              ))}
              {platformVarsList?.length > 0 && <Divider />}
              <Form.Item
                label='是否订阅全部平台告警'
                name='subscribe_all'
                rules={[
                  {
                    required: true,
                    message: t('不能为空'),
                  },
                ]}
                valuePropName='checked'
                initialValue={false}
                tooltip='订阅全部告警，相关变量（平台级、模版级）都会使用预置的默认值，无需再手动填写'
              >
                <Switch onChange={handleChangeSubscribeAll} />
              </Form.Item>
              <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.subscribe_all !== curValues.subscribe_all}>
                {({ getFieldValue }) => {
                  if (!getFieldValue('subscribe_all')) {
                    return (
                      <Form.Item label='告警模板' name='tid' shouldUpdate>
                        <Select onChange={handleTemplateChange} options={platformTemplates.map((item) => ({ label: item.name, value: item.id }))} placeholder='请选择告警模板' />
                      </Form.Item>
                    );
                  } else {
                    return (
                      <>
                        <Divider plain>相关模板</Divider>
                        <List
                          grid={{ gutter: 10, column: 2 }}
                          dataSource={platformTemplates}
                          renderItem={(pts) => (
                            <List.Item>
                              <Card title={pts.name}>
                                {pts.varsList?.map((item, i) => (
                                  <Row gutter={10} key={i}>
                                    <Col span={16}>
                                      <Form.Item
                                        label={`${item.cn}`}
                                        name={['template_vars', `${pts.id}+${item.en}`]}
                                        key={i}
                                        rules={[
                                          {
                                            required: true,
                                            message: `${item.en}不能为空`,
                                          },
                                        ]}
                                        initialValue={item.default}
                                        extra={item.type === 'time' && <>单位：{item.unit}</>}
                                      >
                                        {item.type === 'time' || item.type === 'number' ? (
                                          <InputNumber style={{ width: 250, margin: 3 }} placeholder='请输入该变量对应的值' step={0.1} min={item.min} max={item.max}></InputNumber>
                                        ) : (
                                          <Input placeholder='请输入该变量对应的值' />
                                        )}
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Popover
                                        content={
                                          <>
                                            <p>类型：{item.type}</p>
                                            {item.type === 'time' && <p>单位：{item.unit}</p>}
                                            <p>默认值：{item.default}</p>
                                            {(item.type === 'time' || item.type === 'number') && <p>最小值：{item.min}</p>}
                                            {(item.type === 'time' || item.type === 'number') && <p>最大值：{item.max}</p>}
                                            <p>备注说明：{item.comment}</p>
                                          </>
                                        }
                                        title={item.cn}
                                      >
                                        <Button type='primary'>查看该变量详情</Button>
                                      </Popover>
                                    </Col>
                                  </Row>
                                ))}
                              </Card>
                            </List.Item>
                          )}
                        />
                      </>
                    );
                  }
                }}
              </Form.Item>
              {varsList?.length > 0 && <Divider plain>模版变量</Divider>}
              {varsList?.map((item, i) => (
                <Row gutter={10} key={i}>
                  <Col span={8}>
                    <Form.Item
                      label={`${item.cn}`}
                      name={['template_var', `${item.en}`]}
                      key={i}
                      rules={[
                        {
                          required: true,
                          message: `${item.en}不能为空`,
                        },
                      ]}
                      initialValue={item.default}
                      extra={item.type === 'time' && <>单位：{item.unit}</>}
                    >
                      {item.type === 'time' || item.type === 'number' ? (
                        <InputNumber style={{ width: 250, margin: 3 }} placeholder='请输入该变量对应的值' step={0.1} min={item.min} max={item.max}></InputNumber>
                      ) : (
                        <Input placeholder='请输入该变量对应的值' />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Popover
                      content={
                        <>
                          <p>类型：{item.type}</p>
                          {item.type === 'time' && <p>单位：{item.unit}</p>}
                          <p>默认值：{item.default}</p>
                          {(item.type === 'time' || item.type === 'number') && <p>最小值：{item.min}</p>}
                          {(item.type === 'time' || item.type === 'number') && <p>最大值：{item.max}</p>}
                          <p>备注说明：{item.comment}</p>
                        </>
                      }
                      title={item.cn}
                    >
                      <Button type='primary'>查看该变量详情</Button>
                    </Popover>
                  </Col>
                </Row>
              ))}
              {isShow && (
                <Form.Item label='promql'>
                  <span style={{ color: 'red', fontWeight: 'bold' }}>{promql}</span>
                </Form.Item>
              )}
              <Form.Item>
                {!form.getFieldValue('subscribe_all') && (
                  <Button onClick={preview} type='primary'>
                    预览
                  </Button>
                )}
                {(isShow || form.getFieldValue('subscribe_all')) && (
                  <Button onClick={goNext} type='primary' style={{ marginLeft: 5 }}>
                    下一步
                  </Button>
                )}
                <Button
                  onClick={() => {
                    history.push('/alert-rules?id=1');
                  }}
                  style={{ marginLeft: 5 }}
                >
                  {t('取消')}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
        {currentStep == 1 && (
          <AddAlertForm
            cluster={cluster}
            promql={promql}
            tid={tid}
            childClick={goBack}
            platformId={form.getFieldValue('platform_id')}
            subscribeAll={form.getFieldValue('subscribe_all')}
            templateVars={form.getFieldValue('template_vars')}
            platformVars={form.getFieldValue('platform_vars')}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default CreateAlertByAlertTemplate;
